<template>
  <div class="annex">
    <el-input
      style="margin-bottom: 10px"
      class="inp"
      placeholder="请输入预估金额"
      v-model="projectInformation.projectBudget"
      @input="inputAmount"
      @focus="focusAmount"
      @blur="blurAmount"
      :disabled="disabled"
      >元
      <template slot="prepend">预估成本金额：</template>
    </el-input>
    <UploadList
      :disabled="disabled"
      :tableHeight="null"
      :fields="{ name: 'fileName', path: 'filePath', type: 'projectId' }"
      :value="projectCostPlanFileList"
      @change="change($event)"
      :type="projectInformation.id"
    >
    </UploadList>
    <div style="color: red">* 预估成本方案请上传PDF的格式,目前只有PDF的文件才能预览</div>
  </div>
</template>
<script>
import { getInputValue, delcommafy, numberToCurrencyNo } from '@/util/jsencrypt'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    UploadList: () => import('@/components/upload/List.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileData: [],
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      projectCostPlanFileList: state => state.project.projectCostPlanFileList,
    }),
  },
  methods: {
    /** 值改变触发 "申请金额" 文本框 **/
    inputAmount() {
      this.projectInformation.projectBudget = String(this.projectInformation.projectBudget)
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$forceUpdate()
    },
    /** 获得焦点触发 "申请金额" 文本框 **/
    focusAmount() {
      if (
        this.projectInformation.projectBudget == null ||
        this.projectInformation.projectBudget == '' ||
        this.projectInformation.projectBudget == undefined
      ) {
        this.projectInformation.projectBudget = 0
      } else {
        this.projectInformation.projectBudget = String(
          this.projectInformation.projectBudget
        ).replace(/,/g, '')
      }
      this.$forceUpdate()
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurAmount() {
      this.projectInformation.projectBudget = Number(
        this.projectInformation.projectBudget
      ).toLocaleString()
      this.$forceUpdate()
    },

    change(r) {
      this.$store.commit({
        type: 'project/SET_PROJECTCOSTPLANFILELIST',
        projectCostPlanFileList: r,
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  .inp {
    width: 400px;
  }
}
.operation {
  position: sticky;
  bottom: 0;
  padding: 10px;
  text-align: center;
  .el-button {
    width: 121px;
    margin: 0 15px;
  }
}
</style>
